<template>
  <div class="policy-detail" v-loading="loading">
    <!-- <el-tabs v-model="tabsActiveName" @tab-click="tabsClickHandler"> -->
    <el-tabs v-model="tabsActiveName">
      <el-tab-pane label="概览" name="first">
        <div class="policy-info admin-view-container">
          <el-descriptions :column="3" border>
            <el-descriptions-item label="驾照类型">{{ tableData.usableVehicleType }}</el-descriptions-item>
            <el-descriptions-item label="已补偿次数">{{ tableData.passNum }}</el-descriptions-item>
            <el-descriptions-item label="已补偿金额">{{ tableData.passAmount }}</el-descriptions-item>
          </el-descriptions>
        </div>

        <div class="policy-flow admin-view-container">
          <p>产品信息</p>
          <div class="admin-table-list">
            <el-table :data="productMessage" border>
              <el-table-column prop="productName" label="产品名称" width="120">
                <template slot-scope="scope">
                  <el-link type="primary" @click="tabJumps(scope.row)">{{ scope.row.productName }}</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="policyNo" label="会员服务单号"></el-table-column>
              <el-table-column prop="contractNo" label="合同号"></el-table-column>
              <el-table-column prop="policyTime" label="订单时间" :formatter="$formatter.date(true)"></el-table-column>
              <el-table-column prop="startTime" label="有效期" width="160">
                <template slot-scope="scope">
                  {{ $utils.formatDateFull(scope.row.startTime) + '-' + $utils.formatDateFull(scope.row.endTime) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="contractStatus"
                label="订单状态"
                width="120"
                :formatter="$formatter.dict($dict.contractStatus)"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="policy-flow admin-view-container">
          <p>会员服务流水</p>
          <div class="admin-table-list">
            <el-table :data="tableData.policyFlows" border>
              <el-table-column prop="orderFlowNo" label="流水号"></el-table-column>
              <el-table-column
                prop="orderFlowType"
                label="类型"
                :formatter="$formatter.dict($dict.orderFlowType)"></el-table-column>
              <el-table-column prop="orderFlowAmount" label="金额"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间" :formatter="$formatter.date(true)"></el-table-column>
            </el-table>
          </div>
        </div>
        <admin-space v-p="[20006]">
          <el-button
            type="primary"
            :disabled="forcedOverRefundDisabled"
            @click="forcedOverRefund({businessSn: $props.row.businessSn})">
            强制完结
          </el-button>
        </admin-space>
      </el-tab-pane>

      <el-tab-pane label="补考费会员服务" name="second" v-if="tableData.resitContract">
        <div class="policy-info admin-view-container">
          <el-descriptions :column="3" border>
            <el-descriptions-item label="会员服务合同号">{{ tableData.resitContract.contractNo }}</el-descriptions-item>
            <el-descriptions-item label="会员服务金额">{{ tableData.resitContract.insuredAmount }}</el-descriptions-item>
            <el-descriptions-item label="补偿金额/补偿上限">
              {{ tableData.resitContract.passAmount || 0 }}/{{ tableData.resitCompensateLimit }}
            </el-descriptions-item>
            <el-descriptions-item label="会员服务状态">
              {{ $dict.getData($dict.contractStatus, tableData.resitContract.contractStatus).label }}
            </el-descriptions-item>

            <el-descriptions-item label="有效期限">
              {{
                $utils.formatDateFull(tableData.resitContract.startTime) +
                ' - ' +
                $utils.formatDateFull(tableData.resitContract.endTime)
              }}
            </el-descriptions-item>
            <el-descriptions-item label="科目一限制次数">
              {{ tableData.resitContract.sub1CompensationNum || 0 }}/{{ tableData.resitContract.sub1Restrict }}
              <span
                v-if="tableData.resitContract.sub1CompensationNum >= tableData.resitContract.sub1Restrict"
                style="color: #ff422d"
                >(已达上限)
              </span>
            </el-descriptions-item>
            <el-descriptions-item label="科目二限制次数">
              {{ tableData.resitContract.sub2CompensationNum || 0 }}/{{ tableData.resitContract.sub2Restrict }}
              <span
                v-if="tableData.resitContract.sub2CompensationNum >= tableData.resitContract.sub2Restrict"
                style="color: #ff422d"
                >(已达上限)
              </span>
            </el-descriptions-item>

            <el-descriptions-item label="科目三限制次数">
              {{ tableData.resitContract.sub3CompensationNum || 0 }}/{{ tableData.resitContract.sub3Restrict }}
              <span
                v-if="tableData.resitContract.sub3CompensationNum >= tableData.resitContract.sub3Restrict"
                style="color: #ff422d"
                >(已达上限)
              </span>
            </el-descriptions-item>
            <el-descriptions-item label="加入方式">
              {{ tableData.resitContract.insuranceWay == 1 ? '系统添加' : '手动添加' }}
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div class="compensation-flow admin-view-container">
          <div class="flow-head">
            会员服务补偿流水
            <el-button type="primary" size="small" @click="openExcel(1)">导出数据</el-button>
          </div>
          <div class="admin-table-list">
            <el-table :data="tableData.resitContract.compensateApplies" border>
              <el-table-column prop="compensateApplyNo" label="流水号"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间" :formatter="$formatter.date(true)"></el-table-column>
              <el-table-column
                prop="compensateSub"
                label="补偿科目"
                :formatter="$formatter.dict($dict.examSub)"></el-table-column>
              <el-table-column prop="compensateAmount" label="结算金额"></el-table-column>
              <el-table-column prop="compensateAmount" label="补偿凭证">
                <template slot-scope="scope">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="$dict.SETTING.API_URL + '/image/' + scope.row.compensateReceipt"
                    :preview-src-list="[$dict.SETTING.API_URL + '/image/' + scope.row.compensateReceipt]">
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="赔付状态" prop="compensateApplyStatus">
                <template slot-scope="scope">
                  <span v-if="scope.row.compensateApplyStatus == 1">待审核</span>
                  <span v-if="scope.row.compensateApplyStatus == 2" style="color: #0075ff">审核中</span>
                  <span v-if="scope.row.compensateApplyStatus == 3" style="color: #34b377">已通过</span>
                  <span v-if="scope.row.compensateApplyStatus == 4" style="color: #ff422d">
                    未通过
                    <el-tooltip class="item" effect="light" :content="scope.row.auditReasons" placement="top">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="admin-dialog-btns">
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        ">
        关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import {drvStudentGetStudentFlow, drvStudentExportStudentFlow} from '@/api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      tabsActiveName: 'first',
      tableData: {
        policyNo: '', // 会员服务单号
        policyTime: '', // 会员服务时间
        policyAmount: '', // 会员服务金额
        usableVehicleType: '', // 驾照类型
        resitCompensateLimit: '', // 补考上限金额
        enrollCompensate: '', // 报名金额
        pssNum: '', // 通过次数
        passAmount: '', // 通过金额
        resitContract: {
          // 补考会员服务
          templateType: '', // 会员服务类型 1全科辅导会员(报销补考费)
          contractNo: '', // 合同号
          insuranceWay: '', // 会员服务方式  1系统 2手动
          insuredAmount: '', // 会员服务金额
          contractStatus: '', // 会员服务状态 0已会员服务 1保障中 2已退出  3正常完结 4已超限
          startTime: '', // 生效日期
          endTime: '', // 失效日期
          sub1CompensateNum: '', // 科一补偿次数
          sub1Restrict: '', // 科一限制次数
          sub2CompensateNum: '', //
          sub2Restrict: '', //
          sub3CompensateNum: '', //
          sub3Restrict: '', //
          compensationFlows: [], // 补偿流水
        },
        policyFlows: [], // 会员服务流水
      },
      forcedOverRefundDisabled: false, // 强制完结按钮
      productMessage: [
        // {
        //   productName: '全科辅导会员(报销补考费)',
        //   contractStatus: '', // 会员服务状态 0已会员服务 1保障中 2已退出  3正常完结 4已超限
        //   contractNo: '', // 合同号
        //   startTime: '', // 生效日期
        // },
      ],
      generateButtonStatus: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    tabJumps(row) {
      if (row.productName == '全科辅导会员(报销补考费)') {
        this.tabsActiveName = 'second';
      }
    },

    getData() {
      this.loading = true;
      this.productMessage.length = 0;
      this.forcedOverRefundDisabled = false;
      drvStudentGetStudentFlow({businessSn: this.$props.row.businessSn}).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data;
          this.tableData.sub1CompensationNum = this.tableData.sub1CompensationNum || 0;
          this.tableData.sub2CompensationNum = this.tableData.sub2CompensationNum || 0;
          this.tableData.sub3CompensationNum = this.tableData.sub3CompensationNum || 0;
          if (res.data.data.resitContract) {
            res.data.data.resitContract['productName'] = '全科辅导会员(报销补考费)';
            this.productMessage.push(res.data.data.resitContract);
          }
          if (this.productMessage.length) {
            if (this.productMessage.length == 1) {
              if (this.productMessage[0].contractStatus == 4) {
                this.forcedOverRefundDisabled = true;
              }
            } else {
              let flag = this.productMessage.every((item) => {
                return item.contractStatus == 4;
              });
              this.forcedOverRefundDisabled = flag;
            }
          }
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    async forcedOverRefund(row) {
      let vNode = this.$createElement((await import('./refund')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getData();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '确认完结',
        width: '400px',
      });
    },

    openExcel(type) {
      drvStudentExportStudentFlow({businessSn: this.$props.row.businessSn, templateType: type}).then((res) => {
        if (res.status == 200) {
          this.$utils.downloadFile(res);
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.policy-detail {
  .admin-view-container {
    .flow-head {
      height: 30px;
      margin: 16px 0;
      .el-button {
        position: absolute;
        right: 0;
        // margin-left: 960px;
      }
    }
    p,
    .flow-head {
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }
  }
  .policy-flow {
    margin: 20px 0;
  }
  .admin-methods-btns {
    margin-top: 15px;
  }
  .admin-dialog-btns {
    margin-top: 20px;
  }
}
</style>
